/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Hidden from "@material-ui/core/Hidden";

import LinkButton from "components/LinkButton/LinkButton.jsx";

// @material-ui/icons
import {
  Book,
  DirectionsBike,
  Pool,
  Home,
  DirectionsRun,
  ListAlt,
  Group,
  ShoppingCart,
  PersonAdd,
} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {
  const { classes } = props;
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <LinkButton color="transparent" className={classes.navLink} to="/">
          <Home className={classes.icons} /> Home
        </LinkButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <LinkButton
          color="transparent"
          className={classes.navLink}
          to="/training"
        >
          <Pool className={classes.icons} /> Training
        </LinkButton>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <LinkButton
          color="transparent"
          className={classes.navLink}
          to="/juniors"
        >
          <DirectionsBike className={classes.icons} /> Juniors
        </LinkButton>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <Button color="transparent" className={classes.navLink}>
          <DirectionsBike className={classes.icons} /> Juniors
        </Button>
      </ListItem> */}
      {/* <ListItem className={classes.listItem}>
        <LinkButton
          color="transparent"
          className={classes.navLink}
          to="/events"
        >
          <DirectionsRun className={classes.icons} /> Events
        </LinkButton>
      </ListItem> */}
      <ListItem className={classes.listItem}>
        <LinkButton
          color="transparent"
          className={classes.navLink}
          to="/results"
        >
          <ListAlt className={classes.icons} /> Results
        </LinkButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <LinkButton color="transparent" className={classes.navLink} to="/team">
          <Group className={classes.icons} /> Team
        </LinkButton>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          className={classes.navLink}
          href="https://raceskin.co.uk/product-category/club-shop/sun-city-tri-club/"
          target="_blank"
        >
          <ShoppingCart className={classes.icons} /> Shop
        </Button>
      </ListItem>
      {/* <ListItem className={classes.listItem}>
        <LinkButton color="primary" className={classes.joinLink} to="/team">
          <PersonAdd className={classes.icons} /> Join
        </LinkButton>
      </ListItem> */}
    </List>
  );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
